<template>
  <main class="color-primary h-100 cancellation-bg blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Cancellation') }}
    </p>
    <div class="cancellation-main-content">
      <div class="d-flex justify-content-between">
        <div class="px-2 text-center">
          <label>{{ $t('Transaction number') }}</label>
          <p><b>2344</b></p>
        </div>
        <div class="px-2 text-center">
          <label>{{ $t('Business number') }}</label>
          <p><b>78</b></p>
        </div>
      </div>
    </div>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="tableData"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
      >
      </v-data-table>
    </v-container>
    <div class="cancellation-main-content">  
      <div class="px-2 mt-4">
        <div class="px-2 mt-4">
          <label>{{ $t('Cancelation amount') }}</label>
          <input type="text" class="input" v-model="transaction_number" />
        </div>
        <div class="px-2 mt-4">
          <label>{{ $t('Supplier Approval number') }}</label>
          <input type="text" class="input" v-model="transaction_number" />
        </div>
        <div class="px-2 mt-4">
          <label>{{ $t('Comments') }}</label>
             <textarea
                rows="4"
                class="w-100 bg-white mb-7 px-5 bdbr-2"
                v-model="transaction_number"
              >
              </textarea>
        </div>
        <div class="px-2">
          <button class="primary-btn">
            {{ $t('Check') }}
          </button>
        </div>
      </div>
    </div>
  </main>  
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'CancellationsTransaction',
  data() {
    return {
      headers: [
        {
          text: this.$t('Business name'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('Mobile'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('Supplier ID'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('Product'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('Date'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('Hour'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('Consumer price'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('Cost Price'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
      ],
      tableData: [],
      transaction_number: "",
      business_number: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.cancellation-main-content {
  max-width: 420px;
  margin: 10px auto;
}
</style>